import React, { useEffect } from "react";
import Layout from "./Layout";

const WorkoutSchedule: React.FC = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.id = "bsport-widget-cdn";
    script1.src = "https://cdn.bsport.io/scripts/widget.js";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.id = "bsport-widget-mount";
    script2.innerHTML = `
      function MountBsportWidget(config, repeat=1) {
        if (repeat > 50) { return }
        if (!window.BsportWidget) {
          return setTimeout(() => {
            MountBsportWidget(config, repeat + 1)
          }, 100 * repeat || 1)
        }
        BsportWidget.mount(config)
      }
    `;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.innerHTML = `
      MountBsportWidget({
        "parentElement": "bsport-widget-751814",
        "companyId": 3079,
        "franchiseId": null,
        "dialogMode": 1,
        "widgetType": "calendar", 
        "showFab": false,
        "fullScreenPopup": false,
        "styles": undefined,
        "config": {
          "calendar": {}
        }  
      })
    `;
    document.body.appendChild(script3);

    return () => {
      document.head.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, []);

  return (
    <Layout backgroundColor="var(--color-light-blue)">
      <div className="default-container">
        <div id="bsport-widget-751814"></div>
      </div>
    </Layout>
  );
};

export default WorkoutSchedule;
