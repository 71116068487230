import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'; // Ensure this CSS file exists for styling
import Layout from './Layout'; // Ensure the correct path to Layout

const HomePage: React.FC = () => {
    const [timeLeft, setTimeLeft] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const targetDate = new Date('January 04, 2025 18:00:00').getTime();

        const updateCountdown = () => {
            const now = new Date().getTime();
            const distance = targetDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft(`${days}d - ${hours}h - ${minutes}m - ${seconds}s`);
        };

        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleJoinWaitlist = () => {
        navigate('/waitlist');
    };

    return (
        <Layout backgroundColor="transparent" paddingTop="0px" paddingBottom="0px"> 
            <div className="homepage-container">
                <img src="/logo-text.svg" alt="Logo" className="text-logo inverted" />
                <div className="countdown">{timeLeft}</div>
                <button className="special" onClick={handleJoinWaitlist}>Join the Waitlist</button>
            </div>
        </Layout>
    );
};

export default HomePage;
