import React, { useState } from 'react';
import api from './axiosConfig';
import './WaitlistForm.css'; // Ensure this CSS file exists for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Layout from './Layout'; // Ensure the correct path to Layout

const WaitlistForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Trim spaces from form data
    const trimmedData = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
    };

    const { firstName, lastName, email } = trimmedData;

    if (!firstName || !lastName || !email) {
      setError('First name, last name, and email are required.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError('');

    try {
      const response = await api.post('/bsport/members', {
        firstName: firstName,
        lastName: lastName,
        email: email
      });

      console.log('Member created:', response.data);
      setIsSubmitted(true); // Set submission status to true

      // Navigate back to home after 3 seconds
      setTimeout(() => {
        navigate('/'); // Adjust the path as needed
      }, 3000);
    } catch (error) {
      const err = error as any; // Type assertion to 'any'
      console.error('Error creating member:', err);
      setError(err.response ? err.response.data.error : 'Failed to create member. Please try again.');
    }
  };

  return (
    <Layout backgroundColor="var(--color-light-blue)">
      <div className="default-container">
        <div className="form-container">
          {isSubmitted ? (
            <>
              <h1>Done!</h1>
              <p>You will receive updates soon.</p>
            </>
          ) : (
            <>
              <h1>Strong is the new <span style={{ fontFamily: 'PPEditorialNew' }}>fit.</span></h1>
              <form className="form" onSubmit={handleSubmit}>
                <input type="text" name="firstName" placeholder="First name" className="form-input" value={formData.firstName} onChange={handleChange} />
                <input type="text" name="lastName" placeholder="Last name" className="form-input" value={formData.lastName} onChange={handleChange} />
                <input type="email" name="email" placeholder="Email" className="form-input" value={formData.email} onChange={handleChange} />
                {error && <p className="error-text">{error}</p>}
                <p className="info-text">I hereby permit Lifted Fitness GmbH to use my email address to notify me in regards to special events, and offers.</p>
                <div><button type="submit" className="special">Save</button></div>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default WaitlistForm; 