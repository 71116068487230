import React from 'react';
import Layout from './Layout'; // Ensure the correct path to Layout
import './About.css'; // Import the CSS file for styling

const About: React.FC = () => {
    return (
        <Layout backgroundColor="var(--color-off-white)">
            <div className="about-section">
                <div className="text-content">
                    <h1>Strength for everyone.</h1>
                    <p className="big-description">
                        At Lifted, we believe everyone deserves to feel and be strong. <br /><br />
                        Our mission is to make strength training accessible, enjoyable, and effective for everyone.<br /><br />
                        Join us to become part of a supportive community, to build confidence, to live healthier or to simply enjoy working out.
                    </p>
                </div>
                <img
                    className="image"
                    src="https://res.cloudinary.com/dxznerft3/image/upload/v1732876758/homepage_pictures/strength-for-everyone.png"
                    alt="Strength for Everyone"
                />
            </div>
            <div className="about-section blue-background">
                <img
                    className="image big-6"
                    src="https://res.cloudinary.com/dxznerft3/image/upload/v1732876757/homepage_pictures/lifted-big-6.png"
                    alt="Lifted Big 6"
                />
                <div className="text-content">
                    <h2>The Lifted Formula</h2>
                    <p>
                        Our group workouts are rooted in science, but delivered in an exciting and approachable way. Each 45-minute session is designed to maximize your results. Here's what makes our workouts unique: <br /><br />
                        <span style={{ fontWeight: 'bold' }}>The Lifted Big 6:</span> Each workout focuses on six foundational compound exercises that target large and multiple muscle groups and build strength in the most effective way.<br /><br />
                        <span style={{ fontWeight: 'bold' }}>Building Strength:</span> We aim for few repetitions and slow and controlled movements to allow the use of heavy weights and continuous strength progression. <br /><br />
                        <span style={{ fontWeight: 'bold' }}>Core Power:</span> Every workout ends with a core challenge to strengthen your midsection.<br /><br />
                        <span style={{ fontWeight: 'bold' }}>Holistic Approach:</span> We also include dynamic stretching and focused relaxation exercises to prevent injury, improve flexibility, reduce stress and help you to connect with your body.<br /><br />
                        <span style={{ fontWeight: 'bold' }}>Excellent Trainers:</span> Our trainers are not only the best motivators, they also personalize the workouts to your needs and fitness level, so you can progress at the pace that is best for you.<br /><br />
                        <span style={{ fontWeight: 'bold' }}>Group Vibe:</span> We know you will go those extra 10% because your friend is watching. You'll get more out of your training and feel connected and happy afterwards. Maybe you even meet someone new?<br /><br />
                        <span style={{ fontWeight: 'bold' }}>Tracked:</span> Our software remembers the weights you used, so you don’t have to. This ensures you don’t go too light or heavy, but just at the right level to progress consistently.<br /><br />
                    </p>
                </div>
            </div>
            <div className="about-section">
                <div className="text-content">
                    <h1>Strength: Your Superpower</h1>
                    <p className="big-description">
                        Strength is so much more than just physical power. It's a superpower that can transform your life. When you're strong, you're: <br /><br /></p>
                    <ul className="big-description">
                        <li><span style={{ fontWeight: 'bold' }}>Confident:</span> Feel stronger, and believe in yourself and your capabilities.</li>
                        <li><span style={{ fontWeight: 'bold' }}>Resilient:</span> Bouncing back from challenges will feel easier.</li>
                        <li><span style={{ fontWeight: 'bold' }}>Empowered:</span> Learn to take control of your health and well-being.</li>
                        <li><span style={{ fontWeight: 'bold' }}>Living longer:</span> Build muscle to increase your basal calorie burn, slow down aging and live a healthier life.</li>
                    </ul>
                    <p className="big-description">
                        Strength training isn't just for elite athletes. It's for everyone. But for too long, it's been inaccessible to many. It's been too intimidating, too complex and too often just plain boring.
                    </p>
                </div>
                <img
                    className="image"
                    src="https://res.cloudinary.com/dxznerft3/image/upload/v1732805845/homepage_pictures/strong-is-the-new-fit.png"
                    alt="Strong is the new fit"
                />
            </div>
            <div className='small-section'>
                <div className='text-content'>
                    <h1 style={{ fontFamily: 'PPEditorialNew' }}>We're here to change that!</h1>
                    <p className="big-description">We move beyond the stereotype. We redefine strength training as a journey of growth, connection, and empowerment. In our welcoming studios, expert trainers lead science-backed workouts designed to build physical strength, health and confidence in every individual and as part of a supportive community.</p>
                </div>
            </div>
            <div className='location-section'>
                <div className='text-content'>
                    <h3>Our Locations</h3>
                    <p className="big-description">We are excited to launch our first Lifted studio near U Eberswalder at Schönhauser Alle 48 in Prenzlauer Berg. We open the doors to over 200m² of training floor on January 4th 2025. Join us for the only real strength group fitness classes in Berlin.</p>
                </div>
                <iframe src="https://snazzymaps.com/embed/664235" className="map-iframe" style={{ border: "none" }} loading="lazy"></iframe>
            </div>
        </Layout>
    );
};

export default About;


