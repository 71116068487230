import React, { useEffect } from "react";
import Layout from "./Layout";

const Memberships: React.FC = () => {
    useEffect(() => {
        if (!document.getElementById("bsport-widget-cdn")) {
            const script1 = document.createElement("script");
            script1.id = "bsport-widget-cdn";
            script1.src = "https://cdn.bsport.io/scripts/widget.js";
            document.head.appendChild(script1);
        }

        const mountWidget = (config: any) => {
            const script = document.createElement("script");
            script.innerHTML = `
        function MountBsportWidget(config, repeat=1) {
          if (repeat > 50) { return }
          if (!window.BsportWidget) {
            return setTimeout(() => {
              MountBsportWidget(config, repeat + 1)
            }, 100 * repeat || 1)
          }
          BsportWidget.mount(config)
        }
        MountBsportWidget(${JSON.stringify(config)});
      `;
            document.body.appendChild(script);
            return script;
        };

        const script2 = mountWidget({
            parentElement: "bsport-widget-340870",
            companyId: 3079,
            franchiseId: null,
            dialogMode: 1,
            widgetType: "pass",
            showFab: false,
            fullScreenPopup: false,
            styles: undefined,
            config: {
                pass: {
                    paymentPackCategories: [],
                    privatePassCategories: []
                }
            }
        });

        const script3 = mountWidget({
            parentElement: "bsport-widget-49263",
            companyId: 3079,
            franchiseId: null,
            dialogMode: 1,
            widgetType: "subscription",
            showFab: false,
            fullScreenPopup: false,
            styles: undefined,
            config: {
                subscription: {}
            }
        });

        return () => {
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        };
    }, []);

    return (
        <Layout backgroundColor="var(--color-light-blue)">
            <div className="default-container">
                <h3>Workout Pakete</h3>
                <div id="bsport-widget-340870"></div>
                <h3>Mitgliedschaften</h3>
                <div id="bsport-widget-49263"></div>
            </div>
        </Layout>
    );
};

export default Memberships;
