import React from 'react';
import Layout from './Layout'; // Ensure the correct path to Layout

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout backgroundColor="var(--color-off-white)">
      <div className="default-container">
        <h2>Datenschutzerklärung</h2>
        <br />
        <p><a className="text-link" href="#english-version">Jump to English Version</a></p>
        <br />
        <p>Hinweis: Nur die deutsche Version dieser Datenschutzerklärung ist rechtlich bindend.</p>
        <br />
        <p>Diese Seite informiert Besucher über unsere Richtlinien zur Erfassung, Nutzung und Weitergabe persönlicher Daten bei Nutzung unserer Dienstleistungen.</p>
        <br />
        <p>Indem Sie unseren Service nutzen, erklären Sie sich mit der Erfassung und Verwendung von Informationen gemäß dieser Datenschutzrichtlinie einverstanden. Wir verwenden die erfassten persönlichen Daten ausschließlich zur Bereitstellung und Verbesserung unserer Dienstleistungen und geben sie nur gemäß dieser Datenschutzrichtlinie weiter.</p>
        <br />
        <p>Die in dieser Datenschutzrichtlinie verwendeten Begriffe haben dieselbe Bedeutung wie in unseren <a className="text-link" href="/terms">Allgemeinen Geschäftsbedingungen</a>, sofern in dieser Datenschutzrichtlinie nicht anders definiert.</p>
        <br />
        <h3>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
        <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, die zur Erfüllung eines Vertrags, bei dem Sie Vertragspartei sind, erforderlich sind, ist Artikel 6 (1) Absatz (b) der EU-Datenschutzgrundverordnung (GDPR). Dies gilt auch für vorvertragliche Maßnahmen.</p>
        <br />
        <p>Wenn wir eine Einwilligung zur Verarbeitung personenbezogener Daten einholen, ist die Rechtsgrundlage Artikel 6 (1) Absatz (a) der GDPR. Die Rechtsgrundlage für die Verarbeitung personenbezogener Gesundheitsdaten, wie z.B. die Erfassung der Herzfrequenz und anderer Gesundheitsdaten, ist Artikel 9 (2) Absatz (a) GDPR; zu diesem Zweck holen wir die Einwilligung ein.</p>
        <br />
        <h3>Zugriffsdaten</h3>
        <p>Zur Verbesserung der Qualität unserer Websites und mobilen Apps sammeln wir statistische Daten über die individuellen Zugriffe auf unsere Seiten.</p>
        <br />
        <p>Der Datensatz enthält:</p>
        <br />
        <p>Die Herkunftsseite, den Dateinamen, das Datum und die Uhrzeit der Anfrage, die Datenmenge, den Zugriffsstatus (Datei erfolgreich übertragen oder Datei nicht gefunden), eine Beschreibung des Webbrowsers oder Mobilgeräts, die IP-Adresse des anfordernden Geräts.</p>
        <br />
        <h3>Registrierung</h3>
        <p>Es ist möglich, sich auf unserer Website und in unserer App zu registrieren. Während des Registrierungsprozesses erheben und verarbeiten wir die folgenden Informationen:</p>
        <br />
        <p>E-Mail-Adresse, Vorname, Nachname.</p>
        <br />
        <p>Die Verarbeitung dieser Daten dient der Erstellung Ihres Nutzungsprofils, der Bereitstellung der erforderlichen Zugangsdaten und der Kommunikation mit Ihnen.</p>
        <br />
        {/* <h3>Verifizierung der Telefonnummer</h3>
<p>Nach der Registrierung werden Sie aufgefordert, Ihre Telefonnummer anzugeben und zu verifizieren. Die Verarbeitung dieser Daten ermöglicht es uns, Ihnen einen Verifizierungscode zu senden, um die Richtigkeit der Nummer zu überprüfen und Betrug auf unserer Plattform zu verhindern. Unsere Support-Mitarbeiter haben Zugriff auf diese Daten und können sie verwenden, um mit Ihnen in Kontakt zu treten.</p> */}
        <br />
        <h3>Bestellprozess</h3>
        <p>Im Zuge des Bestellprozesses werden folgende Daten gesammelt:</p>
        <br />
        <p>Name, Adresse, Zahlungsdaten.</p>
        <br />
        <p>Die Erhebung dieser Daten dient ausschließlich der Bearbeitung des Bestellprozesses und erfolgt gemäß den geltenden Gesetzen. Die Daten werden an die beteiligten Unternehmen (Banken, PayPal usw.) übermittelt und gespeichert. Wir bieten Kreditkarte, Debitkarte, PayPal und Lastschrift als Zahlungsmethoden an.</p>
        <br />
        <h3>Sicherheit durch Verschlüsselung</h3>
        <p>Zur Sicherung Ihrer Daten vor unbefugtem Zugriff verwenden wir eine sichere Verschlüsselung. Ihre Daten werden mit einer 256-Bit-SSL-Verschlüsselung (Secure Socket Layer) zwischen Ihrem Computer und unserem Server und umgekehrt übertragen. Dies wird durch das Schlosssymbol in der Statusleiste Ihres Browsers und die https://-Adresse in der Adressleiste angezeigt.</p>
        <br />
        <h3>Newsletter</h3>
        <p>Sie können sich über Neuigkeiten zu unseren Dienstleistungen, neuen Studios, Rabattaktionen und besonderen Veranstaltungen per Newsletter informieren lassen. Wenn Sie den Newsletter nicht mehr empfangen möchten, können Sie ihn jederzeit abbestellen, indem Sie auf den <a className="text-link" href="#">Link</a> am Ende unseres Newsletters klicken.</p>
        <br />
        <h3>Dienste von Drittanbietern</h3>
        <p>Die App nutzt Dienste von Drittanbietern, die möglicherweise Informationen sammeln, mit denen Sie identifiziert werden können. Der allgemeine Zweck besteht darin, unseren Kunden unseren Service zur Verfügung stellen zu können, und wir geben immer nur so viele Informationen weiter, wie für die Nutzung des Dienstes des Drittanbieters erforderlich sind. Im Folgenden führen wir alle von uns genutzten Dienste und deren Zweck auf:</p>
        <br />
        <p>App Store: Wir nutzen den App Store als Vertriebskanal für unsere Apps und erhalten von ihnen Statistiken und Leistungsdaten. Darüber hinaus ermöglicht uns der App Store, Ihnen Push-Benachrichtigungen über Workouts und Updates zu senden.</p>
        <br />
        <p>Bsport: Dieses Tool dient der Verwaltung von Fitnessstudios und ermöglicht die Organisation von Kursen, Mitgliedern, Mitgliedschaften, Trainern, Dienstleistern und Inventar, sowie zur Kundenkommunikation.</p>
        <br />
        <p>Cloudinary: Wir verwenden Cloudinary, um Profilbilder von Kunden und Trainern zu hosten.</p>
        <br />
        <p>Firebase Crashlytics: Dieses Tool hilft uns, Fehler oder Abstürze in unseren Apps zu identifizieren und zu beheben. Es sammelt IP-Adresse, Gerätedetails und unsere Benutzerkennung, um das Problem zu lösen und unsere Dienste zu verbessern.</p>
        <br />
        <p>Google Cloud Platform: Wir nutzen die Google Cloud Platform als Cloud-Service-Anbieter, um unsere Anwendungsdienste und Datenbanken zu hosten. Ihre persönlichen Informationen werden in diesem Dienst verschlüsselt und die Daten werden in EU-Rechenzentren gespeichert.</p>
        <br />
        <p>Google Firebase: Wir verwenden Google Firebase, um Ihnen Push-Benachrichtigungen über Workouts und Updates unseres Dienstes zu senden.</p>
        <br />
        <p>Google Play Services: Der Google Play Services ist ein App-Vertriebsservice, der uns mit Statistiken und der Leistung unserer Apps in seinem Store versorgt.</p>
        <br />
        <p>Google Webfonts: Wir setzen Google Webfonts für Schriftarten auf der Website ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Die Verarbeitung erfolgt aber nur auf unseren Servern.</p>
        <br />
        <p>Google Maps: Wir nutzen Google Maps für Karten auf unserer Website. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Irland.</p>
        <br />
        <p>SendGrid: Wir nutzen SendGrid als Anbieter für E-Mail-Dienstleistungen. Ihre persönlichen Daten werden verwendet, um Ihnen E-Mails zu senden, die für die Nutzung unserer Dienste erforderlich sind.</p>
        <br />
        <p>Stripe & PayPal: Diese Zahlungsanbieter werden verwendet, wenn Sie unsere Dienste abonnieren. Sie benötigen Ihre persönlichen Daten, um Zahlungen einzuziehen, je nach der von Ihnen gewählten Zahlungsart.</p>
        <br />
        <p>YouTube Videos: Wir setzen YouTube Videos für Videos auf der Website ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <br />
        <h3>Sicherheit</h3>
        <p>Wir schätzen es, dass Sie uns Ihre persönlichen Daten anvertrauen, und wir bemühen uns, sie mit geeigneten Mitteln zu schützen. Beachten Sie jedoch, dass keine Übertragung über das Internet oder elektronische Speicherung absolut sicher ist, und wir keine absolute Sicherheit garantieren können.</p>
        <br />
        <h3>Privatsphäre von Kindern</h3>
        <p>Unsere Dienste sind nicht für Personen unter 16 Jahren bestimmt. Wir erheben keine persönlich identifizierbaren Informationen von Kindern unter 16 Jahren. Wenn wir feststellen, dass ein Kind unter 16 Jahren uns persönliche Daten bereitgestellt hat, werden diese sofort von unseren Servern entfernt. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und wissen, dass Ihr Kind uns personenbezogene Daten bereitgestellt hat, bitten wir Sie, sich mit uns in Verbindung zu setzen, damit wir die notwendigen Schritte unternehmen können.</p>
        <br />
        <h3>Inhalte von Dritten</h3>
        <p>Unsere Website und mobile Apps können Inhalte von Dritten wie YouTube-Videos, Google Maps, usw. enthalten. Diese Anbieter speichern normalerweise Cookies auf den Geräten der Nutzer. Durch bestimmte Einstellungen Ihres Browsers oder Telefons kann dies verhindert werden, was jedoch die korrekte Anzeige dieser Inhalte beeinträchtigen kann.</p>
        <br />
        <p>Zudem speichern viele Drittanbieter die IP-Adresse der Nutzer, um die Inhalte an den Browser des Nutzers zu senden. Wir haben keine Kontrolle über die Verwendung der IP-Adresse durch den Drittanbieter.</p>
        <br />
        <h3>Links zu anderen Websites</h3>
        <p>Unser Service kann Links zu externen Websites enthalten. Wenn Sie auf einen solchen Link klicken, werden Sie zu dieser Website weitergeleitet. Bitte beachten Sie, dass wir keine Kontrolle über diese externen Websites haben und keine Verantwortung für ihren Inhalt, ihre Datenschutzrichtlinien oder ihre Praktiken übernehmen. Wir empfehlen Ihnen, die Datenschutzrichtlinien dieser Websites sorgfältig zu lesen.</p>
        <br />
        <h3>Recht auf Widerspruch und Löschung</h3>
        <p>Der Nutzer hat das Recht, seine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen. Durch eine E-Mail an support@lifted-studios.com kann der Nutzer unter anderem der Speicherung seiner personenbezogenen Daten widersprechen.</p>
        <br />
        <p>Im Falle eines Widerspruchs werden alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, gelöscht.</p>
        <br />
        <h3>Datenspeicherung, Kontodeaktivierung und Löschung</h3>
        <p>Wir speichern Daten, bis sie für die Erbringung unserer Dienstleistungen nicht mehr erforderlich sind oder bis Ihr Konto gelöscht wird - je nachdem, was zuerst eintritt. Die Löschung Ihres Kontos ermöglicht auch den Widerruf der Zustimmung zur Speicherung der von Ihnen erhobenen personenbezogenen Daten.</p>
        <br />
        <p>Wenn Sie Ihr Konto löschen, löschen wir Dinge, die Sie gepostet haben, wie z.B. Ihre Fotos, Buchungen, Trainingsergebnisse und Ihre persönlichen Daten in den oben genannten Diensten Dritter, und Sie können diese Informationen später nicht wiederherstellen. Informationen, die andere über Sie geteilt haben, sind nicht Teil Ihres Kontos und werden auch nicht gelöscht. Wenn Sie Ihr Konto nicht löschen möchten, aber die Produkte vorübergehend nicht mehr nutzen möchten, können Sie Ihr Konto stattdessen deaktivieren.</p>
        <br />
        <p>Um Ihr Konto und alle Ihre persönlichen Daten, die auf unseren Servern und in den Diensten Dritter gespeichert sind, jederzeit zu löschen, kontaktieren Sie uns bitte über <a className="text-link" href="mailto:support@lifted-studios.com">support@lifted-studios.com</a> mit dem Betreff "Daten- und Kontoverwaltung" oder verwenden die dafür vorgesehen Funktion in der Einstellungen der Lifted Fitness App.</p>
        <br />
        <p>Sie können auch alle persönlichen Daten, die wir über Sie speichern, anfordern und wir werden Ihnen diese in einem für Menschen und Maschinen lesbaren Format zur Verfügung stellen. Bitte kontaktieren Sie uns über <a className="text-link" href="mailto:support@lifted-studios.com">support@lifted-studios.com</a> mit der Betreffzeile "Datenexport".</p>
        <br />
        <h3>Änderungen an dieser Datenschutzrichtlinie</h3>
        <p>Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, diese Seite regelmäßig auf Änderungen zu überprüfen. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen. Diese Richtlinie ist ab dem 22. März 2023 gültig.</p>
        <br />
        <p>Wenn Sie Fragen oder Anregungen zu unserer Datenschutzrichtlinie haben, zögern Sie nicht, uns unter <a className="text-link" href="mailto:support@lifted-studios.com">support@lifted-studios.com</a> zu kontaktieren.</p>
        <br />
        <h3>Verantwortliche Partei für die Datenverarbeitung</h3>
        <p>Unser Datenschutzbeauftragter ist David Wolf, legal@lifted-studios.com</p>
        <br />
        <h2 id="english-version">Privacy Policy</h2>
        <p>This page informs visitors about our policies regarding the collection, use, and disclosure of personal data when someone uses our service.</p>
        <br />
        <p>By using our service, you agree to the collection and use of information in accordance with this privacy policy. We use the collected personal data solely to provide and improve our services and share it only in accordance with this privacy policy. The terms used in this privacy policy have the same meanings as in our <a className="text-link" href="/terms">Terms and Conditions</a>, unless otherwise defined in this privacy policy.</p>
        <br />
        <h3>Legal Basis for Processing Personal Data</h3>
        <p>The legal basis for processing personal data necessary for the performance of a contract to which you are a party is Article 6 (1) paragraph (b) of the EU General Data Protection Regulation (GDPR). This also applies to pre-contractual measures.</p>
        <br />
        <p>If we obtain consent for the processing of personal data, the legal basis is Article 6 (1) paragraph (a) of the GDPR. The legal basis for processing personal health data, such as collecting heart rate and other health data, is Article 9 (2) paragraph (a) GDPR; for this purpose, we obtain consent.</p>
        <br />
        <h3>Access Data</h3>
        <p>To improve the quality of our websites and mobile apps, we collect statistical data on individual accesses to our pages.</p>
        <br />
        <p>The data set includes:</p>
        <br />
        <p>The origin page, file name, date and time of the request, data volume, access status (file successfully transferred or file not found), a description of the web browser or mobile device, the IP address of the requesting device.</p>
        <br />
        <h3>Registration</h3>
        <p>It is possible to register on our website and in our app. During the registration process, we collect and process the following information:</p>
        <br />
        <p>Email address, first name, last name.</p>
        <br />
        <p>The processing of this data serves to create your user profile, provide the necessary access data, and communicate with you.</p>
        <br />
        {/*
<h3>Phone Number Verification</h3>
<p>After registration, you will be asked to provide and verify your phone number. The processing of this data allows us to send you a verification code to check the accuracy of the number and prevent fraud on our platform. Our support staff have access to this data and can use it to contact you.</p>
*/}
        <br />
        <h3>Order Process</h3>
        <p>During the order process, the following data is collected:</p>
        <br />
        <p>Name, address, payment data.</p>
        <br />
        <p>The collection of this data is solely for processing the order and is done in accordance with applicable laws. The data is transmitted to and stored by the involved companies (banks, PayPal, etc.). We offer credit card, debit card, PayPal, and direct debit as payment methods.</p>
        <br />
        <h3>Security through Encryption</h3>
        <p>To secure your data from unauthorized access, we use secure encryption. Your data is transmitted with 256-bit SSL encryption (Secure Socket Layer) between your computer and our server and vice versa. This is indicated by the lock symbol in your browser's status bar and the https:// address in the address bar.</p>
        <br />
        <h3>Newsletter</h3>
        <p>You can be informed about news regarding our services, new studios, discount promotions, and special events via newsletter. If you no longer wish to receive the newsletter, you can unsubscribe at any time by clicking the link at the end of our newsletter.</p>
        <br />
        <h3>Third-Party Services</h3>
        <p>The app uses third-party services that may collect information used to identify you. The general purpose is to provide our service to our customers, and we only share as much information as necessary for the use of the third-party service. Below we list all the services we use and their purpose:</p>
        <br />
        <p>App Store: We use the App Store as a distribution channel for our apps and receive statistics and performance data from them. Additionally, the App Store allows us to send you push notifications about workouts and updates.</p>
        <br />
        <p>Bsport: This tool is used for managing fitness studios and allows the organization of classes, members, memberships, trainers, service providers, and inventory, as well as customer communication.</p>
        <br />
        <p>Cloudinary: We use Cloudinary to host profile pictures of customers and trainers.</p>
        <br />
        <p>Firebase Crashlytics: This tool helps us identify and fix errors or crashes in our apps. It collects IP address, device details, and our user ID to resolve the issue and improve our services.</p>
        <br />
        <p>Google Cloud Platform: We use the Google Cloud Platform as a cloud service provider to host our application services and databases. Your personal information is encrypted in this service, and the data is stored in EU data centers.</p>
        <br />
        <p>Google Firebase: We use Google Firebase to send you push notifications about workouts and updates to our service.</p>
        <br />
        <p>Google Play Services: Google Play Services is an app distribution service that provides us with statistics and the performance of our apps in its store.</p>
        <br />
        <p>Google Webfonts: We use Google Webfonts for fonts on the website. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland. The processing is done only on our servers.</p>
        <br />
        <p>Google Maps: We use Google Maps for maps on our website. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Ireland.</p>
        <br />
        <p>SendGrid: We use SendGrid as a provider for email services. Your personal data is used to send you emails necessary for the use of our services.</p>
        <br />
        <p>Stripe & PayPal: These payment providers are used when you subscribe to our services. They require your personal data to collect payments, depending on the payment method you choose.</p>
        <br />
        <p>YouTube Videos: We use YouTube videos for videos on the website. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.</p>
        <br />
        <h3>Security</h3>
        <p>We appreciate that you trust us with your personal data, and we strive to protect it with appropriate means. However, please note that no transmission over the internet or electronic storage is absolutely secure, and we cannot guarantee absolute security.</p>
        <br />
        <h3>Privacy of Children</h3>
        <p>Our services are not intended for persons under 16 years of age. We do not knowingly collect personally identifiable information from children under 16. If we discover that a child under 16 has provided us with personal data, we will immediately delete it from our servers. If you are a parent or guardian and are aware that your child has provided us with personal data, please contact us so that we can take the necessary steps.</p>
        <br />
        <h3>Third-Party Content</h3>
        <p>Our website and mobile apps may contain content from third parties such as YouTube videos, Google Maps, etc. These providers usually store cookies on users' devices. Certain settings on your browser or phone can prevent this, but it may affect the correct display of this content.</p>
        <br />
        <p>Additionally, many third-party providers store users' IP addresses to send the content to the user's browser. We have no control over the use of the IP address by the third-party provider.</p>
        <br />
        <h3>Links to Other Websites</h3>
        <p>Our service may contain links to external websites. If you click on such a link, you will be directed to that website. Please note that we have no control over these external websites and assume no responsibility for their content, privacy policies, or practices. We recommend that you read the privacy policies of these websites carefully.</p>
        <br />
        <h3>Right to Object and Deletion</h3>
        <p>The user has the right to withdraw their consent to the processing of personal data at any time. By sending an email to support@lifted-studios.com, the user can, among other things, object to the storage of their personal data.</p>
        <br />
        <p>In the event of an objection, all personal data stored during the contact will be deleted.</p>
        <br />
        <h3>Data Storage, Account Deactivation, and Deletion</h3>
        <p>We store data until it is no longer necessary for providing our services or until your account is deleted, whichever comes first. Deleting your account also allows you to withdraw consent to the storage of the personal data collected from you.</p>
        <br />
        <p>If you delete your account, we delete things you have posted, such as your photos, bookings, training results, and your personal data in the aforementioned third-party services, and you cannot recover this information later. Information that others have shared about you is not part of your account and will not be deleted. If you do not want to delete your account but do not want to use the products temporarily, you can deactivate your account instead.</p>
        <br />
        <p>To delete your account and all your personal data stored on our servers and in third-party services at any time, please contact us at support@lifted-studios.com with the subject "Data and Account Management" or use the function provided in the settings of the Lifted Fitness App.</p>
        <br />
        <p>You can also request all personal data we store about you, and we will provide it to you in a human and machine-readable format. Please contact us at support@lifted-studios.com with the subject line "Data Export".</p>
        <br />
        <h3>Changes to this Privacy Policy</h3>
        <p>We may update our privacy policy from time to time. We therefore recommend that you regularly review this page for changes. We will notify you of any changes by posting the new privacy policy on this page. This policy is effective as of March 22, 2023.</p>
        <br />
        <p>If you have any questions or suggestions about our privacy policy, do not hesitate to contact us at support@lifted-studios.com.</p>
        <br />
        <h3>Responsible Party for Data Processing</h3>
        <p>Our data protection officer is David Wolf, legal@lifted-studios.com</p>
        <br />
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
