import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './HomePage';
import Imprint from './Imprint';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import WaitlistForm from './WaitlistForm';
import WorkoutSchedule from './WorkoutSchedule';
import Memberships from './Memberships';
import About from './About';
import TrainerRecruiting from './TrainerRecruiting';
import TestWorkout from './TestWorkout';

const App: React.FC = () => {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/waitlist" element={<WaitlistForm />} />
        <Route path="/workouts" element={<WorkoutSchedule />} />
        <Route path="/memberships" element={<Memberships />} />
        <Route path="/about" element={<About />} />
        <Route path="/become-a-trainer" element={<TrainerRecruiting />} />
        <Route path="/workout/:workoutId/test" element={<TestWorkout />} />
        {/* Add more routes as needed */}
      </Routes>

    </Router>
  );
};

export default App;
