import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';

const Footer: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        // Check if the user has scrolled to the bottom of the page
        if (scrollY + windowHeight >= documentHeight - 40) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        // If the page is not scrollable, make the footer visible
        if (documentHeight <= windowHeight) {
            setIsVisible(true);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <footer className={`footer ${isHomePage ? 'home' : ''}`} style={style}>
                    <div className='footer-links-left'>
                    <Link to="/imprint" className="footer-link">Impressum & Kontakt</Link>
                    <Link to="/terms" className="footer-link">AGB</Link>
                    <Link to="/privacy-policy" className="footer-link">Datenschutzerklärung</Link>
                    </div>
                    <div className="footer-links-right">
                    <a href="https://www.instagram.com/liftedstudios_" className="footer-link" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="instagram-icon" /> @liftedstudios_
                    </a>
                    </div>
                </footer>
            )}
        </>
    );
};

export default Footer;
