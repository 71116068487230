import React from 'react';
import Layout from './Layout';

const Imprint: React.FC = () => {
  return (
    <Layout backgroundColor="var(--color-off-white)">
      <div className="default-container">
        <h2>Impressum</h2>
        <p>Lifted Fitness GmbH</p>
        <p>Monumentenstraße 35</p>
        <p>10829 Berlin</p>
        <p>Deutschland</p>

        <br />

        <p>Vertretungsberechtigte Geschäftsführer: Frederike Höfermann, David Wolf, Carl Knoblauch</p>
        <p>Registergericht: Berlin Charlottenburg</p>
        <p>Registernummer: HRB 269003 B</p>
        <p>Umsatzsteuer-Identifikationsnummer gem. § 27a UStG: DE405671671</p>

        <br />

        <h3>Kontakt</h3>
        <p>E-Mail: <a href="mailto:support@lifted-studios.com">support@lifted-studios.com</a></p>

        <br />

        <p>Inhaltlich verantwortlich: David Wolf (Anschrift s.o.)</p>
      </div>
    </Layout>
  );
};

export default Imprint;
