import React, { ReactNode } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import "./Layout.css"; // Ensure you have a corresponding CSS file

interface LayoutProps {
  children: ReactNode;
  backgroundColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  backgroundColor = '#FFFCF7',
  paddingTop = '60px',
  paddingBottom = '60px',
}) => {
  return (
    <div
      className="layout-container"
      style={{ backgroundColor, paddingTop, paddingBottom }}
    >
      <NavBar style={{ backgroundColor: backgroundColor}} />
      <main>{children}</main>
      <Footer style={{ backgroundColor: backgroundColor }} />
    </div>
  );
};

export default Layout; 