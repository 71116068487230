import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from './axiosConfig';
import Layout from './Layout'; // Ensure the correct path to Layout


// Define an interface for the workout data
interface Workout {
    id: string;
    start_datetime: string;
    timer_name: string;
    location_name: string;
    playlists: string;
    coach_first_name: string;
    coach_profile_image_url: string;
}

const TestWorkout = () => {
    const { workoutId } = useParams<{ workoutId: string }>();
    const [workout, setWorkout] = useState<Workout | null>(null);
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        // Fetch workout information using the api instance
        api.get(`/workouts/${workoutId}`)
            .then(response => setWorkout(response.data))
            .catch(error => console.error('Error fetching workout:', error));
    }, [workoutId]);

    const handleBooking = () => {
        // Book workout using the api instance
        api.post(`/bsport/join_test_workout/${workoutId}`, { email, name: firstName })
            .then(response => setMessage('Booking successful!'))
            .catch(error => setMessage(error.message));
    };

    return (
        <Layout>
            <div className="default-container">
                <form className="form-container" onSubmit={(e) => { e.preventDefault(); handleBooking(); }}>
                    <h2>Join our test workout</h2>
                    {workout && (
                        <div className="workout-card">
                            <div className="workout-card-left">
                                <img className="profile-avatar" src={workout.coach_profile_image_url} alt="Trainer" />
                                <p>{workout.coach_first_name}</p>
                            </div>
                            <div className="workout-card-right">
                                <h4>{workout.timer_name}</h4>
                                <p>{new Date(workout.start_datetime).toLocaleString('en-DE', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })}</p>
                                <p>{workout.location_name}</p>
                            </div>
                        </div>
                    )}
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First name"
                        className="form-input"
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="form-input"
                    />
                    <button type="submit" className="special">Book</button>
                    {message && <p className="info-text">{message}</p>}
                </form>
            </div>
        </Layout>
    );
};

export default TestWorkout;
